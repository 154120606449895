.page-sidebar-wrapper {
  min-height: 400px;
  margin-top: 30px;
  margin-bottom: 30px
}

@media(min-width: 992px) {
  .page-sidebar-wrapper {
    min-height: 400px;
    margin-top: 60px;
    margin-bottom: 60px
  }
}


.page-sidebar-menu-list {
  list-style-type: none;
  padding: 0px;
}

.page-sidebar-section h1 {
  font-family: Raleway, serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.btn-page-sidebar-link {
  font-family: Raleway, serif;
  font-size: 14pt;
  color: #5a5756;
  text-decoration: none;
  white-space: break-spaces !important;
  text-align: left;
}

.btn-page-sidebar-link:hover {
  color:#0C2D57;
  text-decoration: none;
}

.btn-page-sidebar-link:focus {
  color:#0C2D57;
  text-decoration: none;
}

.btn-page-sidebar-link:active {
  color:#0C2D57;
  text-decoration: none;
}

.btn-page-sidebar-link-active {
  color:#0C2D57;
  font-weight: 500;
  text-decoration: none;
}

.btn-page-sidebar-mobile-toggle {
  line-height: 14px;
  font-size: 32px !important;
  color:#0C2D57;
}
