.live-page {
  padding: 50px 45px;
  font-family: 'Raleway', sans-serif;
}

@media(min-width: 992px) {
  .live-page{
    padding: 50px 100px;
  }
}
