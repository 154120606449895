.page-banner {
  margin-left: -10px;
  margin-right: -10px;
  min-height: 300px;
  background-size: cover !important;
  margin-bottom: 20px;
}

.page-banner-mobile-center {
  background-position: center !important;
}

.page-banner-mobile-right {
  background-position: right !important;
}

.page-banner h1 {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

.page-banner .row {
  height: 300px;
}

.title{
  margin-top: 30px;
  margin-left: 25px;

}

@media(min-width: 992px) {
  .page-banner{
    margin-left: -100px;
    margin-right: -100px;
    min-height: 350px;
    background-position: center !important;
  }
  .page-banner .row {
    height: 350px;
  }
}
