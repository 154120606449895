.home-page-meeting-times {
  text-align: center;
  padding: 50px 45px;
}

@media(min-width: 992px) {
  .home-page-meeting-times{
    padding: 50px 100px;
  }
}

.home-page-meeting-times h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color:#0C2D57;
}

.home-page-meeting-times h3 {
  font-family: 'Crimson Text', serif;
}

.what-to-expect-button {
  color:#0C2D57;
  margin: 0 auto;
  border-color: #0C2D57;
  margin-bottom: 30px;
  box-shadow: none;
}

.what-to-expect-button:hover {
  background-color: #0C2D57 !important;
  border-color: #0C2D57 !important;
  color:white;
}

.what-to-expect-button:active {
  background-color: ##ff5a34 !important;
  border-color: #ff5a34 !important;
  color:white;
}

.what-to-expect-button:focus {
  box-shadow: none;
}

.radio-study-info {
  background-color: yellow;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
}
