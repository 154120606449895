/* .contact-page {
  font-family: 'Raleway', sans-serif;
  margin-bottom: 30px;
} */

.contact-form {
  max-width: 600px;
  margin: auto;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 8px;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
}
.call-pastor-btn {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #0C2D57;
  color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  margin-right: 5px;
}

