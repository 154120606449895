*{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.card1 iframe,
.card2 iframe,
.card3 iframe,
.card4 iframe
{
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.card1 iframe:hover,
.card2 iframe:hover,
.card3 iframe:hover,
.card4 iframe:hover
 {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.card1 h2,
.card2 h2,
.card3 h2,
.card4 h2 {
    margin-bottom: 5px; 
    text-transform: capitalize;
    font-weight: bold;  
}

.card1 p,
.card2 p,
.card3 p,
.card4 p {
    margin-top: 0; 
    text-transform: capitalize;
    font-weight: 400;
    text-align: justify;
    font-size: 16px;
}
