.upcoming-events-container {
    width: 100%;
    padding: 20px;
    background-color: white;
    color: #0C2D57;
    text-align: center; /* Center align the header */
  }
  
  .events-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .event-box {
    background-color: #0C2D57;
    color: white;
    padding: 15px;
    border-radius: 5px;
    flex-basis: calc(33% - 20px); /* Adjust width for 3 boxes in a row minus gap */
    max-width: 300px; /* Prevent the box from becoming too big */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .event-box h3 {
    margin: 0 0 10px 0; /* Add some space below the header */
    font-size: 1.2em; /* Increase font size */
  }
  
  .event-box p {
    font-size: 1em; /* Adjust paragraph font size as needed */
  }
  
  @media (max-width: 768px) {
    .event-box {
      flex-basis: calc(50% - 20px); /* Adjust for smaller screens, 2 boxes in a row */
    }
  }
  
  @media (max-width: 480px) {
    .event-box {
      flex-basis: 100%; /* Full width for very small screens */
    }
  }
  