.box{
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.text{
    color: #0C2D57;
}
