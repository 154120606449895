/* .welcome-section {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
  }
  
  .welcome-title {
    color: #0C2D57;
    margin-bottom: 16px;
  }
  
  .welcome-text {
    color: #333;
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  .welcome-quote {
    background-color: #e8e8e8;
    border-left: 5px solid #0C2D57;
    padding: 10px;
    margin: 16px 0;
    font-style: italic;
  }
  
  .bible-reference {
    display: block;
    text-align: right;
    font-weight: bold;
  }
   */

   *{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .welcome-section {
      background-color: #f4f4f4;
      padding: 20px;
      border-radius: 8px;
      margin: 20px 0;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  
    }
  
    .welcome-title {
      color: #0C2D57;
      margin-bottom: 16px;
    }
    
    .welcome-text {
      color: #333;
      line-height: 1.6;
      margin-bottom: 16px;
    }
    
    .welcome-quote {
      background-color: #e8e8e8;
      border-left: 5px solid #0C2D57;
      padding: 10px;
      margin: 16px 0;
      font-style: italic;
    }
    
    .bible-reference {
      display: block;
      text-align: right;
      font-weight: bold;
    }
    