.file-download-btn {
  padding: 0;
  color: #ff5a34;
}

.file-download-btn:hover {
  color:#ff5a34;
  text-decoration: none;
}

.file-download-btn:focus {
  color:#ff5a34;
  text-decoration: none;
}

.file-download-btn:active {
  color:#ff5a34;
  text-decoration: none;
}
