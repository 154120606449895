.home-page-intro {
  text-align: center;
  padding: 50px 45px;
}

@media(min-width: 992px) {
  .home-page-intro{
    padding: 50px 100px;
  }
}

.home-page-intro h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color:#0C2D57;
}

.home-page-intro p {
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-size: 22px;
}
